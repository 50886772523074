// src/auth0/index.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';
const Auth0Context = createContext();

export const Auth0Provider = ({ domain, clientId, redirectUri, children }) => {
  const navigate = useNavigate();
  const [authChecked, setAuthChecked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(
    document.cookie.split('; ')
      .find(row => row.startsWith('__bb_auth='))?.split('=')[1]);

  const logout = () => {
    console.log("Simulated logout");
    document.cookie = '__bb_auth=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    setToken(null);
    setIsAuthenticated(false);
    setUser(null);
    navigate("/login");
  };

  useEffect(() => {
    const fetchUser = async (token) => {
      try {
        const response = await fetch(`${domain}/v1/user`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          setAuthChecked(true);
          throw new Error('User fetch failed');
        }
        const userData = await response.json();
        setUser(userData);
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 30);
        document.cookie = `__bb_auth=${token}; path=/; expires=${expiryDate.toUTCString()};`;
        setIsAuthenticated(true);
        setAuthChecked(true);
      } catch (error) {
        setAuthChecked(true);
        console.error("Error fetching user:", error);
        document.cookie = '__bb_auth=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
        setToken(null);
        setIsAuthenticated(false);
        setUser(null);
        navigate("/login");
      }
    };
    if (token) {
      fetchUser(token);
      return;
    } else {
      setAuthChecked(true);
    }
  }, [isAuthenticated, authChecked, domain, navigate, token]);

  const loginWithRedirect = async () => {
    console.log("Simulated loginWithRedirect");
    navigate('/login');
  };

  const setAuth = async (token) => {
    setToken(token);
  };

  const getAccessTokenSilently = async () => {
    return token;
  };


  const loginHome = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const dashboardResponse = await fetch(`${domain}/api/v1/dashboards`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (!dashboardResponse.ok) {
        throw new Error(`Error: ${dashboardResponse.status}`);
      }
      const data = await dashboardResponse.json();
      if(data.length > 0) {
        return '/dashboards';
      }
      const { user_metadata } = user;
      if (user_metadata && !user_metadata.first_name) {
        return '/account/finalize';
      }
      return '/account';
    } catch (e) {
      console.log(e.message);
      console.log(e.stack);
    }
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loginWithRedirect,
        logout,
        getAccessTokenSilently,
        authChecked,
        setAuth,
        loginHome
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export const useAuth0 = () => useContext(Auth0Context);

export const withAuthenticationRequired = (Component, { onRedirecting }) => {
  return (props) => {
    const navigate = useNavigate();
    const {
      isAuthenticated,
      authChecked
    } = useAuth0();
    useEffect(() => {
      if(authChecked && !isAuthenticated) {
        navigate("/login");
      }
    }, [authChecked, isAuthenticated, navigate]);
    return isAuthenticated ?
        <Component {...props} />
        : <ReactLoading type="balls" />;
  };
};
