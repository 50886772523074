import { createContext, useContext, useReducer, useEffect } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import { useAuth0 } from "./auth0";
import environments from './environments';

const environment = process.env.REACT_APP_ENVIRONMENT || 'development';
const getPublisherData = async () => {
  try {
    let response = await axios.get(`${environments[environment].REACT_APP_BONBON_API}/api/v1/publishers`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    if (!response.status === 200) {
      throw new Error('response not ok');
    }
    return response.data
  } catch (error) {
    console.error('error getting publisher data', error)
  }
}

const auth0Domain = environments[environment].REACT_APP_BONBON_API;
var publisherData = {};
const UserContext = createContext({});

export function useUser() {
  return useContext(UserContext);
}

const getUserMetadata = async (user, dispatch) => {
    try {
        const accessToken = await user.getToken({
            audience: environments[environment].REACT_APP_AUDIENCE,
            scope: 'read:current_user update:current_user_metadata',
        });
        const metadataResponse = await axios.get(
            `${auth0Domain}/v1/user`,
            { headers: { Authorization: `Bearer ${accessToken}` } },
        );
        const emptyUserMetadata = {
            first_name: '',
            last_name: '',
            gender: null,
            date_of_birth: null,
            zip_code: ''
        };
        const { user_metadata } = metadataResponse.data;
        publisherData = await(getPublisherData());
        publisherData.forEach((pub) => {
          if(user_metadata && !!user_metadata[pub.auth0_client_id]) {
            console.log(pub.pub_name);
            user_metadata[pub.auth0_client_id] = JSON.parse(user_metadata[pub.auth0_client_id]);
            user_metadata[pub.auth0_client_id].name = pub.pub_name;
            console.log(user_metadata);
          }
        });

        if (user_metadata && user_metadata.date_of_birth) {
            user_metadata.date_of_birth = dayjs(user_metadata.date_of_birth);
        }
        dispatch({
          type: 'setMetadata',
          userProps: {
            metadata: {
              ...emptyUserMetadata,
              ...user_metadata
            },
          },
        });
    } catch (e) {
        console.log(e.message);
        console.log(e.stack);
    }
};

function userReducer(user, action) {
  let newUser = {};
  const type = action.type;
  newUser = {
    ...user,
    ...action.userProps,
  };
  switch (type) {
    case 'setUser': {
      getUserMetadata(newUser, newUser.dispatch);
      break;
    }
    case 'setMetadata': {
      break;
    }
    default: {
      break;
    }
  }
  return newUser;
}

const initialUser = {
};

export function UserProvider({ children }) {
  const [user, dispatch] = useReducer(
    userReducer,
    initialUser
  );
  const {
      user: auth0User,
      logout,
      getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    dispatch({ type: 'setDispatch', userProps: { dispatch } });
  }, [dispatch]);

  useEffect(() => {
    if (dispatch && auth0User) {
      dispatch({
        type: 'setUser',
        userProps: {
          ...auth0User,
          getToken: getAccessTokenSilently,
          logout,
        },
      });
    }
  }, [dispatch, getAccessTokenSilently, auth0User, logout]);

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
}
