import React from 'react';
import ReactLoading from 'react-loading';
import { useTheme } from '@mui/material/styles';
import { withAuthenticationRequired } from './auth0';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ProfileForm from './ProfileForm';

const EditProfile = () => {
    const theme = useTheme();

    return (<Box sx={{
        borderRadius: theme.spacing(5),
        backgroundColor: `${theme.palette.primary.background}`,
        p: theme.spacing(7),
        my: theme.spacing(7),
        display: 'flex',
        maxWidth: '592px',
    }}>
        <Box sx={{
            border: `solid 1px ${theme.palette.primary.border}`,
            borderRadius: theme.spacing(3),
            p: theme.spacing(5, 8),
            width: '100%',
        }}>
            <Typography variant="h4" component="h1">Profile</Typography>
            <ProfileForm />
        </Box>
    </Box>);
}

//export default Profile;

export default withAuthenticationRequired(EditProfile, {
    onRedirecting: () => <ReactLoading type="balls" />,
});
