import React from 'react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import { withAuthenticationRequired } from './auth0';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { useUser } from './UserContext.js';

const Profile = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const user = useUser();

    const editMetadata = (event) => {
        navigate('/profile/edit');
    }

    let content;
    if(user.message || user.error) {
        if (user.error) {
            content = (<div><span className="error">Password update failed: {user.error.error || 'Unknown error'} </span></div>)
        } else {
            content = (<div><span className="success">Password update email sent!</span></div>)
        }
    } else if (user && user.metadata) {
        content = (<React.Fragment>
            <Table component="ul">
                <TableRow component="li">
                    <TableCell component="span" sx={{fontWeight: 'bold'}}>Email</TableCell>
                    <TableCell component={Stack} direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography component="span" variant="subtitle2">{user.email}</Typography>
                            <Button
                                variant="text"
                                size="small"
                                onClick={() => user.logout({ returnTo: window.location.origin })}
                            >Not you?</Button>
                        </Stack>
                    </TableCell>
                </TableRow>
                <TableRow component="li">
                    <TableCell component="span" sx={{fontWeight: 'bold'}}>First Name</TableCell>
                    <TableCell component="span">{user.metadata.first_name}</TableCell>
                </TableRow>
                <TableRow component="li">
                    <TableCell component="span" sx={{fontWeight: 'bold'}}>Last Name</TableCell>
                    <TableCell component="span">{user.metadata.last_name}</TableCell>
                </TableRow>
                <TableRow component="li">
                    <TableCell component="span" sx={{fontWeight: 'bold'}}>Gender</TableCell>
                    <TableCell component="span">{user.metadata.gender}</TableCell>
                </TableRow>
                <TableRow component="li">
                    <TableCell component="span" sx={{fontWeight: 'bold'}}>Zip Code</TableCell>
                    <TableCell component="span">{user.metadata.zip_code}</TableCell>
                </TableRow>
                <TableRow component="li">
                    <TableCell component="span" sx={{fontWeight: 'bold'}}>Date of Birth</TableCell>
                    <TableCell component="span">{user.metadata.date_of_birth &&
                        dayjs(user.metadata.date_of_birth).format('MM/DD/YYYY')}</TableCell>
                </TableRow>
            </Table>
            <Button
                variant="contained"
                onClick={editMetadata}
                sx={{mt: theme.spacing(6)}}
            >Edit Profile</Button>
            <Button
                variant="contained"
                onClick={user.updatePassword}
                sx={{
                    mt: theme.spacing(6),
                    ml: theme.spacing(5),
                }}
            >Update Password</Button>
        </React.Fragment>)
    } else {
        content = (<ReactLoading type="balls" />)
    }
    return (<Box sx={{
        borderRadius: theme.spacing(5),
        backgroundColor: `${theme.palette.primary.background}`,
        p: theme.spacing(7),
        my: theme.spacing(7),
        maxWidth: '592px',
        display: 'flex',
    }}>
        <Box sx={{
            border: `${theme.palette.primary.border}`,
            borderRadius: theme.spacing(3),
            width: '100%',
        }}>
            <Typography variant="h4" component="h1">Profile</Typography>
            {content}
        </Box>
    </Box>);
}

//export default Profile;

export default withAuthenticationRequired(Profile, {
    onRedirecting: () => <ReactLoading type="balls" />,
});
