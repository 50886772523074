import React from 'react';
import { useAuth0 } from "./auth0";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const Logout = () => {
    const {
        isAuthenticated,
        logout,
    } = useAuth0();

    return (<Stack direction="row" alignItems="center" spacing={5}>
        {isAuthenticated
            ? <React.Fragment>
                <Button
                    variant="contained"
                    size="medium"
                    onClick={() => logout({ returnTo: window.location.origin })}
                >Log out</Button>
            </React.Fragment>
            : <div></div>
        }
    </Stack>);
    
}

export default Logout;
