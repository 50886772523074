import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import environments from './environments';
import ReactLoading from 'react-loading';
import { useAuth0 } from './auth0';

const environment = process.env.REACT_APP_ENVIRONMENT || "development";
const bonbonApiDomain = environments[environment].REACT_APP_BONBON_API;

const Login = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState('email');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);
  const otpRefs = useRef([]);
  const {
    isAuthenticated,
    loginHome,
    setAuth,
    authChecked
  } = useAuth0();

  useEffect(() => {
    const goHome = async () => {
      const home = await loginHome();
      navigate(home);
    };
    if(isAuthenticated) {
      goHome();
    }
  }, [isAuthenticated, loginHome, navigate]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailSubmit = async () => {
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }
    setEmailError('');
    setLoading(true);
    try {
      const response = await fetch(`${bonbonApiDomain}/v1/earn/otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-bonbon-clientid': 'uJaKUjf8skiSrOLBRyKwYcVS3K6fCk7C'
        },
        body: JSON.stringify({ email, reward: "airpods_v1", newsletter: true, pAds: true, lastLoginUrl: window.location.href })
      });
      if (response.ok) {
        setStep('otp');
        setTimeout(() => otpRefs.current[0].focus(), 100);
      } else {
        alert('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
    setLoading(false);
  };

  const handleOtpPaste = (e) => {
    const pastedData = e.clipboardData.getData('Text').slice(0, 6);
    if (/^\d{6}$/.test(pastedData)) {
      setOtp(pastedData.split(''));
      pastedData.split('').forEach((digit, index) => {
        otpRefs.current[index].value = digit;
      });
      otpRefs.current[5].focus();
    }
  };

  const handleOtpChange = (value, index) => {
    if (!/^[0-9]?$/.test(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value && index < otp.length - 1) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleOtpSubmit = async () => {
    setLoading(true);
    const otpCode = otp.join('');
    try {
      const response = await fetch(`${bonbonApiDomain}/v1/earn/otp-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-bonbon-clientid': 'uJaKUjf8skiSrOLBRyKwYcVS3K6fCk7C'
        },
        body: JSON.stringify({ email, otp: otpCode })
      });
      const data = await response.json();
      if (response.ok && data.earnToken) {
        setAuth(data.earnToken)
      } else {
        alert('Invalid code. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
    setLoading(false);
  };

  const handleKeyPress = (e, action) => {
    if (e.key === 'Enter') {
      action();
    }
  };

  return authChecked && !isAuthenticated ? (
    <div style={{ 
      backgroundColor: 'white', 
      padding: '2rem', 
      borderRadius: '8px', 
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
      maxWidth: '40vw', 
      margin: '2rem auto' 
    }}>
      <Stack direction="column" alignItems="center" spacing={3} width="100%">
        {step === 'email' && (
          <React.Fragment>
            <Typography variant="h6">Login to Bonbon</Typography>
            <TextField
              label="Email" 
              variant="outlined" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              error={!!emailError} 
              helperText={emailError}
              style={{ width: '60%' }} 
              onKeyPress={(e) => handleKeyPress(e, handleEmailSubmit)}
            />
            {loading ? <CircularProgress /> : (
              <Button
                variant="contained"
                size="medium"
                onClick={handleEmailSubmit}
                style={{ width: '40%' }}
              >
                Submit
              </Button>
            )}
          </React.Fragment>
        )}

        {step === 'otp' && (
          <React.Fragment>
            <Typography variant="h6" style={{textAlign: "center"}}>Enter the code sent to <br/> {email}</Typography>
            <Stack direction="row" spacing={1} justifyContent="center">
              {otp.map((digit, index) => (
                <TextField
                  key={index}
                  inputRef={el => otpRefs.current[index] = el}
                  value={digit}
                  onChange={(e) => handleOtpChange(e.target.value, index)}
                  inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                  style={{ width: '3rem' }}
                  onPaste={index === 0 ? handleOtpPaste : undefined}
                  onKeyPress={(e) => index === otp.length - 1 && handleKeyPress(e, handleOtpSubmit)}
                />
              ))}
            </Stack>
            {loading ? <CircularProgress /> : (
              <Button
                variant="contained"
                size="medium"
                onClick={handleOtpSubmit}
              >
                Submit Code
              </Button>
            )}
          </React.Fragment>
        )}
      </Stack>
    </div>
  ) : (<ReactLoading type="balls" />);
};

export default Login;
