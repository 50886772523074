import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuth0, withAuthenticationRequired } from "../auth0";
import { Chart } from "react-charts";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import environments from "../environments";
import ReactLoading from "react-loading";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PauseSwitch from "./components/PauseSwitch";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale
);
const offerBarChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Offer Performance Report',
    },
  },
  scales: {
    OfferViews: {
      position: 'left',
      type: 'logarithmic',
      grid: {
        display:false
      }
    },
    ConversionRate:{
      position:"right",
      grid: {
        display:false
      }
    }
  }
};
const offerBarChartData = {
  labels: [],
  datasets: [
    {
      label: 'Offer Views',
      data: [],
      yAxisID: 'OfferViews',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
    {
      label: 'Offer Conversion Rates',
      data: [],
      yAxisID: 'ConversionRate',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const Show = () => {
  let { dashboardId, dashboardName } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const theme = useTheme();
  const environment = process.env.REACT_APP_ENVIRONMENT || "development";
  const auth0Domain = environments[environment].REACT_APP_BONBON_API;
  const [cpmLiftData, setCpmLiftData] = useState([]);
  const [offers7DayData, setOffers7DayData] = useState({});
  const [offers, setOffers] = useState([]);
  const [sharingBaseUrl, setSharingBaseUrl] = useState("");
  const [regData, setRegData] = useState({});
  const regOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Bonbon Registrations',
      },
    },
  };

  const downloadEmails = async () => {
    alert(
      "Please exercise extra care with emails & metadata, and delete downloaded files once you’ve finished uploading them to your Email Service Provider, etc. Note this download only contains users who opt-in to your emails, which will be a subset of your total registered Addressable Users."
    );
    const accessToken = await getAccessTokenSilently({
      audience: environments[environment].REACT_APP_AUDIENCE,
      scope: "read:current_user update:current_user_metadata",
    });
    const dashboardDataUrlResponse = await axios.get(
      `${environments[environment].REACT_APP_BONBON_API}/api/v1/dashboards/${dashboardId}/emails`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    window.open(dashboardDataUrlResponse.data);
  };

  useEffect(() => {
    const getOfferInfo = async () => {
      const offersResponse = await axios.get(
        `${environments[environment].REACT_APP_BONBON_API}/api/v1/publishers/${dashboardId}/offers`
      );
      let daOffers = offersResponse.data;
      daOffers.forEach((offer) => {
        let config = window.statsig.getConfig(offer.name);
        offer.config = config.value;
        Object.keys(offer.config.signUp).forEach((key) => {
          offer.config.signUp[key] = offer.config.signUp[key].replace(
            "::pubname::",
            dashboardName
          );
        });
      });
      setOffers(daOffers);
    };
    getOfferInfo();
  }, [environment, dashboardId, dashboardName]);

  useEffect(() => {
    if (offers.length > 0) {
      setSharingBaseUrl(offers[0].sharingUrl.split("?")[0]);
    }
  }, [offers]);

  useEffect(() => {
    const getRegistrationData = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: environments[environment].REACT_APP_AUDIENCE,
          scope: "read:current_user update:current_user_metadata",
        });
        const dashboardDataUrlResponse = await axios.get(
          `${environments[environment].REACT_APP_BONBON_API}/api/v2/dashboards/${dashboardId}`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        const dashboardDataResponse = await axios.get(
          dashboardDataUrlResponse.data.loginsUrl
        );
        let totalRegData = dashboardDataResponse.data.filter(
          (e, i) => i > dashboardDataResponse.data.length - 60
        );
        const verifiedDataResponse = await axios.get(
          dashboardDataUrlResponse.data.verifiedUrl
        );
        let verifiedData = verifiedDataResponse.data.filter(
          (e, i) => i > verifiedDataResponse.data.length - 60
        );
        const unverifiedDataResponse = await axios.get(
          dashboardDataUrlResponse.data.unverifiedUrl
        );
        let unverifiedData = unverifiedDataResponse.data.filter(
          (e, i) => i > unverifiedDataResponse.data.length - 60
        );
        setRegData({
          labels: totalRegData.map((r) => r.date),
          datasets: [{
            label: 'Total Registrations',
            data: totalRegData.map((r) => r.registrations),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          }, {
            label: 'Verified Registrations',
            data: verifiedData.map((r) => r.registrations),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          }, {
            label: 'Unverified Registrations',
            data: unverifiedData.map((r) => r.registrations)
          }]
        });
        try {
          console.log(dashboardDataUrlResponse.data.cpmLiftUrl);
          const cpmLiftDataResponse = await axios.get(
            dashboardDataUrlResponse.data.cpmLiftUrl
          );
          console.log("cpmLiftData", cpmLiftDataResponse.data);
          setCpmLiftData(cpmLiftDataResponse.data);
        } catch(e) {
          console.log("error with cpmLiftData", e);
        }
        try {
          console.log(dashboardDataUrlResponse.data.offers7DayUrl);
          const offers7DayDataResponse = await axios.get(
            dashboardDataUrlResponse.data.offers7DayUrl
          );
          console.log("offers7DayData", offers7DayDataResponse.data);
          const data = offers7DayDataResponse.data.filter((d) => (d.conversion_rate > 0));
          offerBarChartData.labels = data.map((d) => d.offerId);
          offerBarChartData.datasets[1].data = data.map((d) => d.conversion_rate);
          offerBarChartData.datasets[0].data = data.map((d) => d.offer_view);
          setOffers7DayData(offerBarChartData);
          console.log("setOffers7DayData", offerBarChartData);
        } catch(e) {
          console.log("error with offers7DayData", e);
        }
      } catch (e) {
        console.log(e.message);
        console.log(e.stack);
        if (e.message.indexOf("401") > -1) {
          navigate("/");
          return;
        }
      }
    };
    getRegistrationData();
  }, [getAccessTokenSilently, auth0Domain, environment, dashboardId, navigate]);

  const primaryAxisCPM = React.useMemo(
    () => ({
      getValue: datum => datum.userType,
    }),
    []
  );

  const secondaryAxesCPM = React.useMemo(
    () => [
      {
        getValue: datum => datum.cpm,
        elementType: "bar",
        min: 0
      },
    ],
    []
  );

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [offerValue, setOfferValue] = React.useState(0);

  const handleOfferChange = (event, newValue) => {
    setOfferValue(newValue);
  };

  if (Object.keys(regData).length > 0) {
    return (
      <div id="body" className="status">
        <div style={{ margin: "12px 0" }}>
          <Typography
            component={Link}
            sx={{ color: theme.palette.primary.main, textDecoration: "none" }}
            variant="p"
            href={`/dashboards`}
          >
            All Dashboards
          </Typography>
          <Typography
            component={Link}
            sx={{ color: theme.palette.primary.main, textDecoration: "none" }}
            variant="p"
            href={`/dashboards`}
          >
            &nbsp;>&nbsp;
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.secondary,
              textDecoration: "none",
            }}
            variant="p"
          >
            {dashboardName}
          </Typography>
        </div>
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Bonbon Portal Dashboard"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab label="Users" {...a11yProps(0)} />
            <Tab label="Offers" {...a11yProps(1)} />
            <Tab label="Settings" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={value} index={0} style={{ flexGrow: "inherit" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2>
                  Addressable User Growth{" "}
                  <span style={{ fontSize: "12px", verticalAlign: "middle" }}>
                    (approx. last 60 days)
                  </span>
                </h2>
                <button
                  onClick={() => {
                    downloadEmails();
                  }}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "black",
                    color: "white",
                    border: "none",
                    padding: "9px",
                  }}
                >
                  Download All Emails <DownloadIcon />
                </button>
              </div>
              <div style={{ width: "100%", height: "400px" }}>
                <Line
                  options={regOptions}
                  data={regData}
                />
              </div>
            </div>
            {cpmLiftData.length > 0 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2>
                    CPM Lift{" "}
                  </h2>
                </div>
                <div style={{ width: "100%", height: "400px" }}>
                  <Chart
                    options={{
                      data: cpmLiftData,
                      primaryAxis: primaryAxisCPM,
                      secondaryAxes: secondaryAxesCPM,
                      primaryAxisType: 'band'
                    }}
                  />
                </div>
              </div>
            )}
            {Object.keys(offers7DayData).length > 0 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2>
                    7-day Offer Conversion Rate Report{" "}
                  </h2>
                </div>
                <div style={{ width: "100%", height: "400px" }}>
                  <Bar options={offerBarChartOptions} data={offers7DayData} />
                </div>
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={1} style={{ flexGrow: "inherit" }}>
            <div>
              <h2>Promote your Bonbon offers</h2>
              <p>
                Bring your audience back to {dashboardName} by promoting an
                offer in just 3 simple steps:
              </p>

              {offers.length === 0 && (
                <ReactLoading type="balls" color="#3443EF" />
              )}
              {offers.length > 0 && (
                <ul style={{ listStyleType: "decimal" }}>
                  <li>
                    <label htmlFor="url">
                      Enter the destination URL where you want to send visitors
                      and click "Update Share URL"
                    </label>
                    <input
                      id="url"
                      name="url"
                      type="text"
                      style={{ height: "3em", width: "50%" }}
                      value={sharingBaseUrl}
                      onChange={(e) => {
                        setSharingBaseUrl(e.target.value);
                      }}
                    />
                    <br />
                    <button
                      type="button"
                      onClick={() => {
                        offers.forEach((offer) => {
                          offer.sharingUrl = `${sharingBaseUrl}?openModal=1&utm_campaign=bonbon-${offer.name}`;
                        });
                        setOffers(offers);
                      }}
                    >
                      Update Share URL
                    </button>
                  </li>
                  <li>
                    Choose the offer you'd like to promote from the list below
                    and click "copy to clipboard"
                    <div
                      style={{
                        gap: "4px",
                      }}
                    >
                      <Tabs
                        value={offerValue}
                        onChange={handleOfferChange}
                        aria-label="Bonbon Portal Offer Tabs"
                      >
                        {offers.map((offer, idx) => (
                          <Tab
                            key={`offer_${idx}`}
                            label={
                              offer.name.length > 12
                                ? `${offer.name.substring(0, 12)}...`
                                : offer.name
                            }
                            {...a11yProps(idx)}
                            style={{ fontSize: "10px" }}
                          />
                        ))}
                      </Tabs>
                      {offers.map((offer, idx) => (
                        <TabPanel value={offerValue} index={idx} key={`tabpanel_${idx}`}>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                padding: "8px",
                                border: "1px solid #eee",
                                borderRadius: "12px",
                              }}
                            >
                              <div style={{ maxWidth: "500px" }}>
                                <h2
                                  style={{ margin: 0 }}
                                  dangerouslySetInnerHTML={{
                                    __html: offer.config.signUp.h1,
                                  }}
                                />
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: offer.config.signUp.h2,
                                  }}
                                />
                              </div>
                              <img
                                alt={offer.name}
                                style={{ maxWidth: "200px" }}
                                src={offer.config.companionImagePath}
                              />
                            </div>
                            <a
                              href={offer.sharingUrl}
                              rel="noreferrer"
                              target="_blank"
                            >
                              View on {dashboardName} &raquo;
                            </a>
                            <br />
                            <a
                              href="#0"
                              onClick={async () => {
                                await navigator.clipboard.writeText(
                                  offer.sharingUrl
                                );
                                alert(`Link copied for ${offer.name}.`);
                              }}
                            >
                              Copy to clipboard &raquo;
                            </a>
                          </div>
                        </TabPanel>
                      ))}
                      {/**/}
                      {/*))}*/}
                    </div>
                  </li>
                  <li>
                    Share the link on social media (X, Facebook, Instagram,
                    Pinterest etc) or as a part of your email marketing
                  </li>
                </ul>
              )}
              <p>
                That's it... users will see your post on social media or your
                email, visit your site and login to win!
              </p>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2} style={{ flexGrow: "inherit" }}>
            <div>
              <h1>{dashboardName} Settings</h1>
              <div style={{ width: "100%", height: "400px" }}>
                <PauseSwitch dashboardId={dashboardId} />
              </div>
            </div>
          </TabPanel>
        </Box>
      </div>
    );
  }
  return <ReactLoading type="balls" color="#3443EF" />;
};

export default withAuthenticationRequired(Show, {
  onRedirecting: () => <ReactLoading type="balls" />,
});
