import { React, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import { useAuth0, withAuthenticationRequired } from "../auth0";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import axios from "axios";
import environments from "../environments";

const Index = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [offers, setOffers] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const environment = process.env.REACT_APP_ENVIRONMENT || "development";
  const bonbonApiDomain = environments[environment].REACT_APP_BONBON_API;
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getOffers = async () => {
      const offerResponse = await axios.get(
        `${bonbonApiDomain}/api/v1/offers`
      );
      setOffers(offerResponse.data.sort((a,b) => a.id > b.id ? 1 : -1));
    };
    getOffers();
  }, [bonbonApiDomain]);

  useEffect(() => {
    const getAccessToken = async () => {
      if(!user) {
        navigate('/');
      }
      if(!user.isBonboner) {
        navigate('/account');
      }
      const token = await getAccessTokenSilently({
        audience: environments[environment].REACT_APP_AUDIENCE,
        scope: "read:current_user update:current_user_metadata",
      });
      setAccessToken(token);
    };
    getAccessToken();
  });

  if (offers && accessToken) {
    return (
      <div>
        <div style={{ margin: "12px 0" }}>
          <Typography
            component={Link}
            sx={{ color: theme.palette.primary.main, textDecoration: "none" }}
            variant="p"
            href={`/offers`}
          >
            All Offers
          </Typography>
          <div style={{float: "right"}}>
            <a href="/offers/new">Create New Offer &raquo;</a>
          </div>
        </div>
        <Box
          sx={{
            borderRadius: theme.spacing(5),
            backgroundColor: `${theme.palette.primary.background}`,
            p: theme.spacing(7),
            my: theme.spacing(7),
            display: "flex",
            maxWidth: "592px",
          }}
        >
          <Box
            sx={{
              border: `solid 1px ${theme.palette.primary.border}`,
              borderRadius: theme.spacing(3),
              p: theme.spacing(5, 8),
              width: "100%",
            }}
          >
            <ul>
              {offers.map((offer) => {
                return (<li><a href={`/offers/${offer.id}`}>{offer.id}</a></li>);
              })}
            </ul>
          </Box>
        </Box>
      </div>
    );
  } else {
    return <ReactLoading type="balls" color="#3443EF" />;
  }
};

//export default Profile;

export default withAuthenticationRequired(Index, {
  onRedirecting: () => <ReactLoading type="balls" />,
});
