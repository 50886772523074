import React from 'react';
import ReactLoading from 'react-loading';
import { withAuthenticationRequired } from './auth0';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

const Account = () => {
    const theme = useTheme();

    return (<React.Fragment><Box sx={{
        borderRadius: theme.spacing(5),
        backgroundColor: `${theme.palette.primary.background}`,
        p: theme.spacing(7),
        my: theme.spacing(7),
        display: 'inline-block',
    }}>
        <Box sx={{
            border: `solid 1px ${theme.palette.primary.border}`,
            borderRadius: theme.spacing(3),
            p: theme.spacing(5, 8),
        }}>
            <Typography variant="h4" component="h1">Thank you!</Typography>
            <Typography variant="body1" component="span" sx={{display: 'block', my: theme.spacing(4)}}>Rewards activated</Typography>
            {/*
            <Button
                variant="contained"
                href="https://www.sohh.com/"
                component={Link}
            >Return to SOHH</Button>
            */}
        </Box>
    </Box>
    <Stack component="ul" sx={{listStyleType: 'none', pl: theme.spacing(0)}}>
        <Box component="li" sx={{listStyleType: 'none', mb: theme.spacing(5)}}>
            <Typography
                component={Link}
                sx={{color: theme.palette.primary.main, textDecoration: 'none'}}
                variant="subtitle1"
                href="/account/publishers"
            >Deactivate publisher account or opt-out of emails</Typography>
        </Box>
        <Box component="li">
            <Typography
                component={Link}
                sx={{color: theme.palette.primary.main, textDecoration: 'none'}}
                variant="subtitle1"
                href="https://form.jotform.com/231217750728153"
            >Permanently delete Bonbon account</Typography>
        </Box>
    </Stack></React.Fragment>);
}

export default withAuthenticationRequired(Account, {
    onRedirecting: () => <ReactLoading type="balls" />,
});
