import { React, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useAuth0, withAuthenticationRequired } from "../auth0";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import environments from "../environments";
import axios from "axios";

const Show = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [offer, setOffer] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const environment = process.env.REACT_APP_ENVIRONMENT || "development";
  const bonbonApiDomain = environments[environment].REACT_APP_BONBON_API;
  const { offerId } = useParams();
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getOffer = async () => {
      const offerResponse = await axios.get(
        `${bonbonApiDomain}/api/v1/offers/${offerId}`
      );
      setOffer(offerResponse.data);
    };
    getOffer();
  }, [bonbonApiDomain, offerId]);

  useEffect(() => {
    const getAccessToken = async () => {
      if(!user) {
        navigate('/');
      }
      if(!user.isBonboner) {
        navigate('/account');
      }
      const token = await getAccessTokenSilently({
        audience: environments[environment].REACT_APP_AUDIENCE,
        scope: "read:current_user update:current_user_metadata",
      });
      setAccessToken(token);
    };
    getAccessToken();
  });

  if (offer && accessToken) {
    return (
      <div>
        <div style={{ margin: "12px 0" }}>
          <Typography
            component={Link}
            sx={{ color: theme.palette.primary.main, textDecoration: "none" }}
            variant="p"
            href={`/offers`}
          >
            All Offers
          </Typography>
          <Typography
            component={Link}
            sx={{ color: theme.palette.primary.main, textDecoration: "none" }}
            variant="p"
            href={`/offers`}
          >
            &nbsp;>&nbsp;
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.secondary,
              textDecoration: "none",
            }}
            variant="p"
          >
            {offerId}
          </Typography>
          &nbsp;&nbsp; | &nbsp;&nbsp;
          <Typography
            component={Link}
            target="_blank"
            sx={{ color: theme.palette.primary.main, textDecoration: "none" }}
            variant="p"
            href={`https://console.statsig.com/6Lty2BzWZxpapClggYrIiU/dynamic_configs/${offerId}`}
          >
            Edit on Statsig &raquo;
          </Typography>
          
        </div>
        <Box
          sx={{
            borderRadius: theme.spacing(5),
            backgroundColor: `${theme.palette.primary.background}`,
            p: theme.spacing(7),
            my: theme.spacing(7),
            display: "flex",
            maxWidth: "592px",
          }}
        >
          <Box
            sx={{
              border: `solid 1px ${theme.palette.primary.border}`,
              borderRadius: theme.spacing(3),
              p: theme.spacing(5, 8),
              width: "100%",
            }}
          >
            <dl>
              <dt>Heading 1</dt>
              <dd>{offer.defaultValue.signUp.h1}</dd>
              <dt>Heading 2</dt>
              <dd dangerouslySetInnerHTML={{__html: offer.defaultValue.signUp.h2}}></dd>
              <dt>Reward Label</dt>
              <dd>{offer.defaultValue.reward.displayName}</dd>
              <dt>Image</dt>
              <dd><img src={offer.defaultValue.companionImagePath} alt={offer.id} style={{width:"200px"}}/></dd>
              <dt>Click Text</dt>
              <dd>{offer.defaultValue.signUp.clickText}</dd>
            </dl>
          </Box>
        </Box>
      </div>
    );
  } else {
    return <ReactLoading type="balls" color="#3443EF" />;
  }
};

//export default Profile;

export default withAuthenticationRequired(Show, {
  onRedirecting: () => <ReactLoading type="balls" />,
});
