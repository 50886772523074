import * as React from 'react';
import ReactLoading from 'react-loading';
import { withAuthenticationRequired } from './auth0';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { useUser } from './UserContext.js';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const PublisherList = () => {
    const user = useUser();
    const theme = useTheme();

    if (user.metadata) {
      return (<>
        <Typography sx={{mt: theme.spacing(7)}} variant="h4" component="h1">Bonbon Login Sites</Typography>
        <List sx={{
        border: `solid 2px ${theme.palette.primary.border}`,
        borderRadius: theme.spacing(1),
        backgroundColor: `${theme.palette.primary.background}`,
        paddingBotton: theme.spacing(0),
        mt: theme.spacing(3),
        mb: theme.spacing(7),
      }}>
        { Object.entries(user.metadata).filter(([key, value]) => value &&value.rewards)
          .map(([key, value]) => (
          <ListItem
            key={key}
            secondaryAction={<PublisherActions
              rewards={value.rewards}
              preferences={value.preferences}
              publisher={key}
            />}
            sx={{
              borderBottom: `solid 2px ${theme.palette.primary.border}`,
              '&:last-child': {
                borderBottom: 'none',
              }
            }}
            >
            <ListItemAvatar>
              <Badge variant="dot" anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} color="secondary" badgeContent=" " invisible={!user.metadata[key].signedIn}>
                <Avatar variant="rounded">{value.name.slice(0, 1).toUpperCase()}</Avatar>
              </Badge>
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant="h6" component="h3">{value.name}</Typography>}
              secondary={<Typography>{value.signedUpAt ? `Added on ${value.signedUpAt}`: 'Active'}</Typography>}
            />
          </ListItem>
        )) }
    </List>
      </>)
    } else {
      return (<ReactLoading type="balls" />);
    }
};

const PublisherActions = (props) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const user = useUser();
    const theme = useTheme();
    let rewardType = props.rewards[0].name ? props.rewards[0].name.split(':')[0] : "see fewer ads";
    rewardType = rewardType.replace(/./, c => c.toUpperCase())

    const handleClick = (event) => {
      const menuText = event.currentTarget.innerHTML.trim().split('<').shift();
      if (menuText.toLowerCase().includes('opt-out')) {
        user.dispatch({ type: 'opt-out-publisher', publisher: props.publisher });
      } else if (menuText.toLowerCase().includes('deactivate')) {
        user.dispatch({ type: 'deactivate-publisher', publisher: props.publisher });
      }
      setOpen(!open);
    }

    return (
      <Stack direction="row" spacing={theme.spacing(2)}>
        <Chip
          variant="outlined"
          size="large"
          label={rewardType}
          sx={{
            borderColor: theme.palette.primary.border,
            borderRadius: '5px',
            height:'auto',
            padding: theme.spacing(0, 5),
          }}
        />
          <IconButton
            sx={{
              border: `1px solid ${theme.palette.primary.border}`,
              borderRadius: '5px',
            }}
            variant="outlined"
            onClick={handleClick}
            ref={anchorRef}
          >
            <MoreVertIcon
              color="action"
              fontSize="medium"
            />
          </IconButton>
          <Popper
            anchorEl={anchorRef.current}
            open={open}
            placement="bottom-end"
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList>
                  <MenuItem onClick={handleClick}>
                    <Link
                      href="https://form.jotform.com/231216812044041"
                      sx={{color: theme.palette.primary.black, textDecoration: 'none'}}
                    >Opt-out of emails</Link>
                  </MenuItem>
                  <MenuItem onClick={handleClick}>
                    <Link
                      href="https://form.jotform.com/231217205402137"
                      sx={{color: theme.palette.primary.black, textDecoration: 'none'}}
                    >Deactivate account</Link>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
      </Stack>
    );
};

export default withAuthenticationRequired(PublisherList, {
    onRedirecting: () => (<ReactLoading type="balls" />),
});
